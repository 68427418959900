import { useState, useEffect } from "react";
import { useStyles } from "./styles";
import axios from "axios";
import {
  FormControl,
  TextField,
  Typography,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import Logo from "../../assets/skillzLogo.png";

enum Keydowns {
  ESCAPE = "Escape",
  ENTER = "Enter",
}

export const LoginPage = () => {
  const { classes } = useStyles();
  const [credentials, setCredentials] = useState<Record<string, string>>({
    name: "",
    password: "",
  });
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const authorizationURL = "https://competition-api.skillz.com/auth";
  const showDuration = 6000;

  const config = {
    headers: {
      "context-type": "application/json",
    },
  };

  const handleChangeCredentials = (e: any) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsSnackbarOpen(false);
  };

  const sendRequestToGetCookies = async () => {
    const body = JSON.stringify(credentials);

    try {
      await axios.post(authorizationURL, body, config);
      window.location.replace("/home");
    } catch (e) {
      console.log(e);
      setIsSnackbarOpen(true);
    }
  };

  const handleEscapeKeyPress = (e: KeyboardEvent) => {
    if (e.key === Keydowns.ENTER) {
      sendRequestToGetCookies();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscapeKeyPress, false);

    return () => {
      window.removeEventListener("keydown", handleEscapeKeyPress, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <img className={classes.image} src={Logo} alt="Skillz logo" />
        <p className={classes.title}>Competition Engine Docs</p>
      </div>
      <FormControl className={classes.formContainer}>
        <Typography>Sign in</Typography>
        <TextField
          fullWidth
          id="name"
          label="Username"
          name="name"
          autoFocus
          onChange={(e) => handleChangeCredentials(e)}
        />
        <TextField
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          onChange={(e) => handleChangeCredentials(e)}
        />
        <Button onClick={sendRequestToGetCookies}>Sign in</Button>
      </FormControl>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={showDuration}
        onClose={handleClose}
      >
        <Alert severity={"error"}>Login attempt failed. Try again.</Alert>
      </Snackbar>
    </div>
  );
};
