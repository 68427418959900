import { LoginPage } from "./pages/LoginPage";
import { GlobalStyles } from "tss-react";

function App() {
  return (
    <>
      <GlobalStyles
        styles={{
          body: {
            margin: 0,
            padding: 0,
            boxSizing: "border-box",
          },
        }}
      />
      <LoginPage />
    </>
  );
}

export default App;
