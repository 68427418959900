import { makeStyles } from "tss-react/mui";

export const useStyles = makeStyles()({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    backgroundColor: "#414C5D",

    "& .MuiSnackbar-root": {
      right: "24px",
      justifyContent: "flex-end",
    },

    "&::before": {
      content: `''`,
      display: "block",
      position: "absolute",
      background: "linear-gradient(#F1F2F8,#3F4D5D,#F1F2F8)",
      clipPath:
        "polygon(91% 40%, 100% 0, 100% 35%, 100% 70%, 100% 100%, 50% 100%, 0 100%, 1% 100%, 41% 91%, 69% 76%)",
      width: "100vw",
      height: "100vh",
      bottom: 0,
      right: 0,
      zIndex: "200",
      opacity: 0.1,
    },
  },
  formContainer: {
    gap: "1.5rem",
    width: "500px",
    padding: "4rem 4rem 2rem 4rem",
    borderRadius: "1.5rem",
    backgroundColor: "#FFF",
    zIndex: "300",
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
    gap: "1.5rem",
    padding: "1rem",
  },
  image: {
    width: 70,
    height: 70,
  },
  title: {
    fontSize: "1.5rem",
    color: "#fff",
  },
  errorMessage: {
    color: "#F5003A",
    textAlign: "center",
    fontSize: "14px",
  },
});
